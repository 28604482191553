/*
 * @Description: 封装整理使用的element-ui插件
 * @Author: 小蜜蜂写作（liu）
 */
import Vue from 'vue'
import {
    Button,
    Container,
    Header,
    Aside,
    Main,
    Footer,
    Menu,
    MenuItem,
    Submenu,
    Breadcrumb,
    BreadcrumbItem,
    Card,
    Row,
    Col,
    Dialog,
    Form,
    FormItem,
    Select,
    Option,
    Input,
    Notification,
    Message,
    MessageBox,
    Tooltip,
    Loading,
    Collapse,
    CollapseItem,
    Table,
    TableColumn,
    TabPane,
    Tabs,
    Upload,
    Radio,
    RadioButton,
    RadioGroup,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Popover,
    Tag,
    Pagination,
    Image,
    Drawer
  } from 'element-ui'
  Vue.use(Button)
  Vue.use(Image)
  Vue.use(Container)
  Vue.use(Header)
  Vue.use(Aside)
  Vue.use(Main)
  Vue.use(Footer)
  Vue.use(Menu)
  Vue.use(MenuItem)
  Vue.use(Submenu)
  Vue.use(Breadcrumb)
  Vue.use(BreadcrumbItem)
  Vue.use(Card)
  Vue.use(Row)
  Vue.use(Col)
  Vue.use(Dialog)
  Vue.use(Form)
  Vue.use(FormItem)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(Input)
  Vue.use(Tooltip)
  Vue.use(Collapse)
  Vue.use(CollapseItem)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(TabPane)
  Vue.use(Tabs) 
  Vue.use(Upload) 
  Vue.use(Radio) 
  Vue.use(RadioButton) 
  Vue.use(RadioGroup) 
  Vue.use(Dropdown) 
  Vue.use(DropdownItem) 
  Vue.use(DropdownMenu) 
  Vue.use(Popover)
  Vue.use(Tag)
  Vue.use(Pagination)
  Vue.use(Drawer)
  Vue.prototype.$notify = Notification
  Vue.prototype.$message = Message
  Vue.prototype.$msgbox = MessageBox
  Vue.prototype.$confirm = MessageBox.confirm
  Vue.prototype.$loading = Loading.service