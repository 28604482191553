<template>
  <div id="app">
    <!-- 路由占位符 -->
    <router-view></router-view>
  </div>
</template>

<script>
import { sleep } from '@/utils/sleep'

export default {
  name: 'App',
}
</script>

<style lang="less" scoped>

</style>
