import axios from 'axios';
import router from './router';
axios.defaults.timeout = 8000;
import { Message } from "element-ui";
 
// axios 配置
//生产环境
 axios.defaults.baseURL = 'https://admin.littlebeelearn.com';
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
 //前端连后端本地服务
 // axios.defaults.baseURL = 'http://192.168.0.2:8082/';


// http request 拦截器
axios.interceptors.request.use(
  config => {
    if (localStorage.token) { //判断token是否存在  
      config.headers.Authorization = localStorage.token;  //将token设置成请求头
    }
    config.headers.language = "zh";
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);
 
// http response 拦截器
axios.interceptors.response.use(
  response => {
    const config = response.config;
      console.log(config,'2')
      return response;
  },
    error => {
      if(error.response!=undefined){
          const { status } = error.response
          if (status === 401) { // 未授权 
              localStorage.clear() // 清除token
              Message({
                message: error.response.data.detail, 
                type: "error",
              })
              router.replace('/login'); // 跳转到登录页

          } else if (status === 404) { // 资源不存在
            Message({
              message: '请求资源不存在',
              type: "error",
                  });
          } else if (status >= 500) { // 服务端异常
            Message({
              message: '服务端异常，请稍后重试',
              type: "error",
            })
          }else if (status == 400) { // 服务端异常
            console.log("error",error)
            Message({
              message: error.response.data.detail, 
              type: "error",
            })
          }
          return Promise.reject(error);
        }
}
);
export default axios;